
import { commitAddNotification } from '@/store/main/mutations';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { NavigationGuardNext, Route } from 'vue-router';
import { DynamicList } from '@/interfaces/api/general/dynamicList';
import { FormItem } from '@/interfaces/lib/model';
import { Sds, SdsDynamicListResult } from '@/interfaces/model/general/sds';
import { Department } from '@/interfaces/model/general/department';
import { MAX_LIMIT } from '@/lib/constant';
import { pdfDisplayOptions, statusList } from '@/lib/formItem';
import { removeLastPdfExtension } from '@/lib/formatter';
import { casNumberPattern } from '@/lib/regex';
import router from '@/router';
import { store } from '@/store/index';
import {
  dispatchCancelCreateSimpleExportRequest,
  dispatchDeleteDynamicListResult,
  dispatchGetDynamicLists,
  dispatchGetExportCreateSimple,
  dispatchGetExportCreateSimpleV3,
  dispatchPostDynamicListResult,
  dispatchPutDynamicListResult,
  dispatchUpdateCompanySdses,
  dispatchGetCompanyUploadUsers,
} from '@/store/main/actionsMain';
import SdsBottomSheet from '@/views/dashboard/bottomSheet/SdsBottomSheet.vue';
import CompanySdsTable from '@/views/dashboard/CompanySdsTable.vue';
import DownloadProgress from '@/views/dashboard/DownloadProgress.vue';
import SdsSearchForm from '@/views/dashboard/SdsSearchForm.vue';
import SdsFilterForm from '@/views/pages/sdsIndex/SdsFilterForm.vue';
import SdsCurrentFilter from '@/views/pages/sdsIndex/SdsCurrentFilter.vue';
import SdsTableDrawer from '@/views/dashboard/sdsDrawer/SdsTableDrawer.vue';
import {
  useSdsComposable,
  SortState,
  ActualUseSdsComposable,
} from '@/composables/useSds';
import ExportSdses from '@/views/pages/sdsIndex/ExportSdses.vue';
import { TagResponse } from '@/interfaces/model/general/tag';
import { UpdateCompanySdsesRequestBody } from '@/interfaces/api/general/company';
import { useSelectedItemsComposable } from '@/composables/useSelectedItems';
import { useDepartmentComposable } from '@/composables/useDepartment';
import { usePermissionComposable } from '@/composables/usePermission';
import { useTagComposable } from '@/composables/useTag';
import { NewSds } from '@/lib/constructor/model/general/sds';
import { useDownloadExportSdses } from '@/composables/useDownloadExportSdses';
import {
  convertFilterParamsToRequest,
  SdsesFilterParamsKeys,
  SdsesFilterParamsValue,
  useSdsFilter,
} from '@/composables/useSdsFilter';
import { User } from '@/interfaces/vuex/user';

export default defineComponent({
  components: {
    SdsBottomSheet,
    CompanySdsTable,
    SdsTableDrawer,
    SdsSearchForm,
    SdsFilterForm,
    SdsCurrentFilter,
    DownloadProgress,
    ExportSdses,
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (this.isEditing) {
      const isAllowed: boolean = window.confirm(
        '編集中の作業は保存されませんが、よろしいですか？'
      );
      if (isAllowed) {
        next();
      }
    } else {
      next();
    }
  },
  setup() {
    const showSdsDetailView = ref<boolean>(false);
    const pdfName = ref<string>('');
    const keywordList = ref<Array<string>>([]);
    const productFormItems = ref<Array<{ text: string; value: number }>>([]);
    const materialFormItems = ref<Array<FormItem>>([]);
    const casFormItems = ref<Array<FormItem>>([]);
    const page = ref<number>(1);
    const limit = ref<number>(50);
    const isEditing = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const canExportCreateSimpleCount = ref<number>(0);
    const canExportCreateSimpleCountV3 = ref<number>(0);
    const dialogShow = ref<boolean>(false);

    const isChange = ref<number>(0);
    const isSdsFilterOpen = ref(false);
    const dynamicLists = ref<Array<DynamicList>>([]);
    const uploadUsers = ref<User[]>([]);
    const activeSds = ref<Sds>(NewSds);
    const currentIndex = ref(0);

    const {
      sds,
      sdses,
      total,
      pdfBlob,
      companyUploadUsers,
      fetchCompanySds,
      fetchCompanySdses,
      fetchSdsPdf,
      fetchCompanySdsesCount,
      fetchCompanySdsesCountWithCondition,
      updateCompanySds,
      deleteCompanySdses,
    } = useSdsComposable();
    const { getCompanyDepartments, companyDepartments } =
      useDepartmentComposable();
    const { tags, getCompanyTag } = useTagComposable();
    const [
      selectedSdses,
      isSelectedAllSdses,
      isSelectedIndeterminateSdses,
      isSelectedWholeSdses,
      toggleSelectedSds,
      toggleAllSelectedSdses,
      toggleWholeSelectedSdses,
      resetSelectedSdses,
    ] = useSelectedItemsComposable<Sds>(
      sdses.value,
      (sds1, sds2) => sds1.uuid === sds2.uuid
    );
    const { hasPermission, PERMISSIONS: P } = usePermissionComposable();
    const {
      currentFilterParams,
      updateFormFilterParam,
      applyFormFilterParams,
      formFilterParams,
      statusOptions,
      userOptions,
      departmentOptions,
      tagOptions,
      hasFilterParams,
    } = useSdsFilter(uploadUsers, tags, companyDepartments);
    const { downloadExportSdses } = useDownloadExportSdses();

    const sortState = ref<SortState>({
      name: 'none',
      revised_at: 'none',
      created_at: 'none',
    });

    const handleClickProductName = (name: string): void => {
      updateFormFilterParam('productName', name);

      applyFilterParams();
    };

    const handleClickTag = (tag: TagResponse): void => {
      const newParams = Array.from(
        new Set([...formFilterParams.value.tagIds, tag.id])
      );
      updateFormFilterParam('tagIds', newParams);

      applyFilterParams();
    };

    const handleClickDepartment = (department: Department): void => {
      const newParams = Array.from(
        new Set([...formFilterParams.value.departmentUuids, department.uuid])
      );
      updateFormFilterParam('departmentUuids', newParams);

      applyFilterParams();
    };

    const handleClickStatus = (statusGroupId: string): void => {
      const newParams = Array.from(
        new Set([...formFilterParams.value.statusGroupId, statusGroupId])
      );
      updateFormFilterParam('statusGroupId', newParams);

      applyFilterParams();
    };

    const removeFilterParams = (
      key: SdsesFilterParamsKeys,
      value: SdsesFilterParamsValue
    ) => {
      updateFormFilterParam(key, value);

      applyFilterParams();
    };

    const toggleSort = (column: string): void => {
      if (sortState.value[column] === 'none') {
        sortState.value[column] = 'asc';
      } else if (sortState.value[column] === 'asc') {
        sortState.value[column] = 'desc';
      } else {
        sortState.value[column] = 'none';
      }
      Object.keys(sortState.value).forEach((key) => {
        if (key !== column) {
          sortState.value[key] = 'none';
        }
      });
      applyFilterParams();
    };

    const cancelExportCreateSimple = (): void => {
      dispatchCancelCreateSimpleExportRequest(store);
      dialogShow.value = false;
    };

    watch(showSdsDetailView, (next) => {
      if (next === false) {
        activeSds.value = NewSds;
      }
    });

    const hasPrevSds = computed((): boolean => {
      return currentIndex.value > 0;
    });

    const hasNextSds = computed((): boolean => {
      return currentIndex.value < sdses.value.length - 1;
    });

    const showPrevSds = (): void => {
      const index = currentIndex.value - 1;

      showSdsDetail(sdses.value[index], index);
    };

    const showNextSds = async (): Promise<void> => {
      const index = currentIndex.value + 1;

      showSdsDetail(sdses.value[index], index);
    };

    const resetPagenation = (): void => {
      page.value = 1;
    };

    const showSdsDetail = async (item: Sds, index: number): Promise<void> => {
      await doFetchCompanySds(item.uuid);
      await doFetchPdf(item.uuid);

      showSdsDetailView.value = true;
      activeSds.value = sds.value;
      isLoading.value = true;

      canExportCreateSimpleCount.value = 0;
      canExportCreateSimpleCountV3.value = 0;

      currentIndex.value = index;

      productFormItems.value.splice(0);
      productFormItems.value.push(
        ...item.sds_products.map((x, idx) => {
          return {
            text: x.name,
            value: idx,
          };
        })
      );
      materialFormItems.value.splice(0);
      casFormItems.value.splice(0);
      for (const product of sds.value.sds_products) {
        // material_list内のcas_listをcasNumberPatternでマッチングし、1つ以上マッチしたらcanExportCreateSimpleCountV3をインクリメント
        const casList = product.sds_section3.material_list
          .map((x) => x.cas_list)
          .flat()
          .filter((x) => x.cas_number.match(casNumberPattern));
        if (casList.length > 0) {
          canExportCreateSimpleCountV3.value++;
        }

        for (const material of product.sds_section3.material_list) {
          materialFormItems.value.push({
            text: material.material_name,
            value: material.material_name,
          });
          for (const cas of material.cas_list) {
            casFormItems.value.push({
              text: cas.cas_number,
              value: cas.cas_number,
            });
            if (cas.cas_number.match(casNumberPattern)) {
              canExportCreateSimpleCount.value++;
            }
          }
        }
      }

      isLoading.value = false;
    };

    const doFetchPdf = async (sdsId: string): Promise<void> => {
      fetchSdsPdf({
        companyId: store.state.main.company.uuid,
        sdsId: sdsId,
      });
    };

    const doFetchCompanySds = async (sdsId: string): Promise<void> => {
      await fetchCompanySds({
        sdsId: sdsId,
      });
    };

    const doDeleteCompanySdses = async (): Promise<void> => {
      isLoading.value = true;
      try {
        await deleteCompanySdses({
          companyId: store.state.main.company.uuid,
          data: {
            ...convertFilterParamsToRequest(currentFilterParams.value),
            sds_uuid_list: isSelectedWholeSdses.value
              ? []
              : selectedSdses.value.map((sds) => sds.uuid),
            is_selected_whole_sdses: isSelectedWholeSdses.value,
            keyword_list: keywordList.value,
          },
        });

        resetStatuses();
        await fetchSdses();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const doUpdateCompanySds = async (
      sdsId: string,
      data: Sds,
      tags: TagResponse[],
      departments: Department[]
    ): Promise<void> => {
      await updateCompanySds(
        store.state.main.company.uuid,
        sdsId,
        data,
        departments,
        tags,
        store.state.main.userProfile
      );
      await doFetchCompanySds(data.uuid);
      await fetchSdses();
    };

    const fetchDynamicLists = async () => {
      dynamicLists.value.splice(0);

      const res = await dispatchGetDynamicLists(store, {
        page: page.value - 1,
        limit: MAX_LIMIT,
        showAll: false,
      });
      if (res && res.data) {
        dynamicLists.value = [...res.data.items];
      }
    };

    const fetchUploadUsers = async () => {
      const res = await dispatchGetCompanyUploadUsers(store, {
        companyId: store.state.main.company.uuid,
      });

      uploadUsers.value = [...res.data];
    };

    const cancelEdit = async (sdsId: string): Promise<void> => {
      isLoading.value = true;
      isChange.value++;
      try {
        await doFetchCompanySds(sdsId);

        productFormItems.value.splice(0);
        productFormItems.value.push(
          ...sds.value.sds_products.map((x, idx) => {
            return {
              text: x.name,
              value: idx,
            };
          })
        );
        materialFormItems.value.splice(0);
        casFormItems.value.splice(0);
        for (const product of sds.value.sds_products) {
          for (const material of product.sds_section3.material_list) {
            materialFormItems.value.push({
              text: material.material_name,
              value: material.material_name,
            });
            for (const cas of material.cas_list) {
              casFormItems.value.push({
                text: cas.cas_number,
                value: cas.cas_number,
              });
            }
          }
        }
      } finally {
        isChange.value++;
        isLoading.value = false;
      }
    };

    const confirmSave = (event): void => {
      if (isEditing.value) {
        event.preventDefault();
        event.returnValue = '編集中の作業は保存されませんが、よろしいですか？';
      }
    };

    const exportCreateSimple = async (sdsId): Promise<void> => {
      try {
        dialogShow.value = true;

        const res = await dispatchGetExportCreateSimple(store, {
          sdsId: sdsId,
        });
        if (res) {
          const blob = new Blob([res.data], {
            type: 'application/zip',
          });
          const exportSds = sdses.value.find((x) => x.uuid === sdsId);
          //for type guard
          if (!exportSds) {
            return;
          }
          const link = document.createElement('a');

          const fileName = removeLastPdfExtension(exportSds.name);
          const now = new Date();

          // 日本のタイムゾーンに変換
          const japanTime = new Intl.DateTimeFormat('ja-JP', {
            timeZone: 'Asia/Tokyo',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(now);

          // YYYY-MM-DD HH:mm:ss 形式に整形
          const timestampString = japanTime
            .replace(/\//g, '-')
            .replace(/\s/, ' ')
            .replace(/:/g, '');

          link.href = URL.createObjectURL(blob);
          link.download = `${fileName}_${timestampString}_CREATE SIMPLE v2.5.1.zip`;
          link.click();
          URL.revokeObjectURL(link.href);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dialogShow.value = false;
      }
    };

    const exportCreateSimpleV3 = async (sdsId): Promise<void> => {
      try {
        dialogShow.value = true;

        const res = await dispatchGetExportCreateSimpleV3(store, {
          sdsId: sdsId,
        });
        if (res) {
          const blob = new Blob([res.data], {
            type: 'application/zip',
          });
          const exportSds = sdses.value.find((x) => x.uuid === sdsId);
          //for type guard
          if (!exportSds) {
            return;
          }

          const link = document.createElement('a');

          const fileName = removeLastPdfExtension(exportSds.name);
          const now = new Date();

          // 日本のタイムゾーンに変換
          const japanTime = new Intl.DateTimeFormat('ja-JP', {
            timeZone: 'Asia/Tokyo',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(now);

          // YYYY-MM-DD HH:mm:ss 形式に整形
          const timestampString = japanTime
            .replace(/\//g, '-')
            .replace(/\s/, ' ')
            .replace(/:/g, '');

          link.href = URL.createObjectURL(blob);
          link.download = `${fileName}_${timestampString}_CREATE SIMPLE v3.0.2.zip`;
          link.click();
          URL.revokeObjectURL(link.href);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dialogShow.value = false;
      }
    };

    const editDynamicListResult = async (
      sdsId: string,
      dynamicListId: number,
      dynamicListResult: SdsDynamicListResult
    ): Promise<void> => {
      try {
        const res = await dispatchPutDynamicListResult(store, {
          sdsId: sdsId,
          dynamicListId: dynamicListId,
          dynamicListResult: dynamicListResult,
        });

        if (res) {
          await doFetchCompanySds(sdsId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const addDynamicListResult = async (
      sdsId: string,
      dynamicListId: number
    ): Promise<void> => {
      try {
        const res = await dispatchPostDynamicListResult(store, {
          sdsId: sdsId,
          dynamicListId: dynamicListId,
        });
        if (res) {
          await doFetchCompanySds(sdsId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const deleteDynamicListResult = async (
      sdsId: string,
      dynamicListId: number
    ): Promise<void> => {
      try {
        const res = await dispatchDeleteDynamicListResult(store, {
          sdsId: sdsId,
          dynamicListId: dynamicListId,
        });
        if (res) {
          await doFetchCompanySds(sdsId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const toggleSdsFilter = (): void => {
      isSdsFilterOpen.value = !isSdsFilterOpen.value;
    };

    const updateSelectedSdses = async (
      form: Omit<UpdateCompanySdsesRequestBody, 'sds_uuid_list'>
    ) => {
      isLoading.value = true;
      try {
        const res = await dispatchUpdateCompanySdses(store, {
          companyId: store.state.main.company.uuid,
          data: {
            ...form,
            ...convertFilterParamsToRequest(currentFilterParams.value),
            sds_uuid_list: isSelectedWholeSdses.value
              ? []
              : selectedSdses.value.map((sds) => sds.uuid),
            is_selected_whole_sdses: isSelectedWholeSdses.value,
            keyword_list: keywordList.value,
          },
        });
        if (res && res.data) {
          commitAddNotification(store, {
            content: '選択したSDSを更新しました。',
            color: 'success',
          });
        }
        await fetchSdses();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const resetStatuses = () => {
      resetSelectedSdses();
      resetPagenation();
    };

    const handleChangeKeywords = async () => {
      resetStatuses();
      await fetchSdses();
    };

    const fetchSdses = async () => {
      const requestFilterParams = convertFilterParamsToRequest(
        currentFilterParams.value
      );

      await Promise.all([
        //TODO: useSdsのanyが取れたら外す
        (
          fetchCompanySdsesCount as ActualUseSdsComposable['fetchCompanySdsesCount']
        )({
          companyId: store.state.main.company.uuid,
          data: {
            ...requestFilterParams,
            keyword_list: keywordList.value,
          },
        }),

        (fetchCompanySdses as ActualUseSdsComposable['fetchCompanySdses'])({
          companyId: store.state.main.company.uuid,
          data: {
            ...requestFilterParams,
            limit: limit.value,
            page: page.value - 1,
            sortState: sortState.value,
            keyword_list: keywordList.value,
          },
        }),

        (
          fetchCompanySdsesCountWithCondition as ActualUseSdsComposable['fetchCompanySdsesCountWithCondition']
        )({
          companyId: store.state.main.company.uuid,
          data: {
            ...requestFilterParams,
            keyword_list: keywordList.value,
          },
        }),
      ]);
    };

    const fetchSds = async (uuid: string) => {
      await doFetchCompanySds(uuid);
      keywordList.value.push(sds.value.name);

      currentIndex.value = sdses.value.findIndex((x) => x.uuid === uuid);
      const item = sdses.value.find((x) => x.uuid === uuid);
      if (item) {
        showSdsDetail(item, sdses.value.indexOf(item));
      }
    };

    const applyFilterParams = async () => {
      applyFormFilterParams();
      resetStatuses();
      isLoading.value = true;
      try {
        await fetchSdses();
      } finally {
        isLoading.value = false;
      }
    };

    const resetFilters = (): void => {
      updateFormFilterParam('tagIds', []);
      updateFormFilterParam('statusGroupId', []);
      updateFormFilterParam('uploadUsers', []);
      updateFormFilterParam('departmentUuids', []);
      updateFormFilterParam('sdsName', '');
      updateFormFilterParam('productName', '');
      updateFormFilterParam('revisedAtStartDate', '');
      updateFormFilterParam('revisedAtEndDate', '');
      updateFormFilterParam('createdAtStartDate', '');
      updateFormFilterParam('createdAtEndDate', '');
      updateFormFilterParam('isLatestOnly', false);
      applyFilterParams();
    };

    onMounted(async (): Promise<void> => {
      //export queryがあればダウンロード処理を行う
      await downloadExportSdses();

      window.onbeforeunload = confirmSave;

      isLoading.value = true;

      try {
        await Promise.all([
          getCompanyDepartments(),
          fetchDynamicLists(),
          fetchUploadUsers(),
          getCompanyTag(),
        ]);

        const uuid = router.currentRoute.query.uuid;
        if (uuid && typeof uuid === 'string') {
          await fetchSdses();
          await fetchSds(uuid);
        } else {
          await fetchSdses();
        }
      } finally {
        isLoading.value = false;
      }
    });

    return {
      resetFilters,
      sortState,
      toggleSort,
      companyUploadUsers,
      keywordList,
      pdfBlob,
      pdfName,
      limit,
      total,
      page,
      statusList,
      pdfDisplayOptions,
      sds,
      currentFilterParams,
      formFilterParams,
      hasFilterParams,
      statusOptions,
      userOptions,
      tagOptions,
      departmentOptions,
      updateFormFilterParam,
      removeFilterParams,
      applyFilterParams,
      handleChangeKeywords,
      sdses,
      selectedSdses,
      isSelectedAllSdses,
      isSelectedIndeterminateSdses,
      isSelectedWholeSdses,
      toggleSelectedSds,
      toggleAllSelectedSdses,
      toggleWholeSelectedSdses,
      resetSelectedSdses,
      isLoading,
      isEditing,
      showSdsDetailView,
      productFormItems,
      materialFormItems,
      casFormItems,
      showPrevSds,
      showNextSds,
      hasPrevSds,
      hasNextSds,
      showSdsDetail,
      doDeleteCompanySdses,
      doUpdateCompanySds,
      cancelEdit,
      resetPagenation,
      fetchSdses,
      doFetchCompanySds,
      doFetchPdf,
      exportCreateSimple,
      exportCreateSimpleV3,
      dialogShow,
      cancelExportCreateSimple,
      canExportCreateSimpleCount,
      canExportCreateSimpleCountV3,
      dynamicLists,
      uploadUsers,
      tags,
      companyDepartments,
      fetchDynamicLists,
      editDynamicListResult,
      addDynamicListResult,
      deleteDynamicListResult,
      activeSds,
      isSdsFilterOpen,
      toggleSdsFilter,
      isChange,
      updateSelectedSdses,
      hasPermission,
      P,
      handleClickProductName,
      handleClickTag,
      handleClickDepartment,
      handleClickStatus,
    };
  },
});
